import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { useState } from "react";

export const VideoPlayer = ({ url }) => {
  const [loading, setLoading] = useState(true);

  const handleLoading = () => {
    setLoading(false);
  };
  if (!url) {
    return <></>;
  }

  return (
    <Box
      style={{
        borderRadius: "20px",
        border: loading ? "4px solid inherit" : "4px solid #000",
        overflow: "hidden",
        verticalAlign: "middle"
      }}
      sx={{
        display: "flex",
        m: "auto",
        width: "100%",
        height: "100%"
      }}
    >
      <iframe
        title="Youtube video"
        style={{
          display: loading ? "none" : "block",
          width: "100%",
          height: "100%",
          border: "none"
        }}
        src={url}
        frameBorder="0"
        onLoad={handleLoading}
        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      >
        {" "}
      </iframe>
      {loading && (
        <CircularProgress
          size="3rem"
          color="primary"
          sx={{
            display: "flex",
            alignItems: "bottom",
            margin: "auto",
            justifyContent: "center",
            alignSelf: "center",
            minHeight: "250px"
          }}
        />
      )}
    </Box>
  );
};
