import { ReactNode, createContext, useState, useEffect } from "react";

// @type
import {
  CalendarEvent,
  CalendarContextProps,
  CalendarSettings,
  CalendarOperationType
} from "../@types/calendar";
import { db } from "firebaseConfig";
import useAuth from "hooks/useAuth";
import { useModal } from "mui-modal-provider";
import { CalendarEventDialog } from "components/__haim/Common/CalendarEventDialog";
import useFirestore from "hooks/useFirestore";
import { useSnackbar } from "notistack5";
import axios from "axios";
import { parseFirestoreDocument } from "utils/Haim/firestoreUtils";
import useApp from "hooks/useApp";

// ----------------------------------------------------------------------

const CalendarContext = createContext({} as CalendarContextProps);

type CalendarProviderProps = {
  children: ReactNode;
};

function CalendarProvider({ children }: CalendarProviderProps) {
  const [calendarSettings, setCalendarSettings] = useState<CalendarSettings>(null);
  const [calendarSettingsId, setCalendarSettingsId] = useState<string>(null);
  const [events, setEvents] = useState<CalendarEvent[]>(null);
  const [initialShouldFetchEvents, setInitialShouldFetchEvents] = useState<boolean>(false);
  const [isFetchingEvents, setIsFetchingEvents] = useState<boolean>(false);

  const [currentEvent, setCurrentEvent] = useState<CalendarEvent | null>(null);
  const [calendarDialogOpen, setCalendarDialogOpen] = useState(false);

  const { userSettings } = useFirestore();
  const { allContacts } = useApp();

  const { showModal } = useModal();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!user || !userSettings || !!calendarSettingsId) {
      return;
    }

    const fetchCalendarSettings = async () => {
      console.log("Fetching calendars owned by user...");

      return await db
        .collection("calendar-settings-v2")
        .where("owners", "array-contains", user.email)
        .onSnapshot(async (snapshot) => {
          console.log("Calendars owned by user:", snapshot.size);

          if (!snapshot.empty) {
            // TODO - handle multiple calendars
            setCalendarSettingsId(snapshot.docs[0].id);
            setCalendarSettings(snapshot.docs[0].data() as CalendarSettings);
          } else {
            console.log("The user has no calendar set up - creating a new calendar");
            const newCalendarSettings: CalendarSettings = {
              admin: user.email,
              owners: [user.email],
              calendarName: user.email,
              ownerFullName: userSettings.ownerFullName,
              ownerPhoneNumberInternational: userSettings.ownerPhoneNumber,
              reminderHourBeforeEvent: true,
              reminderOnMorningDayBefore: false,
              reminderOnEveningBeforeEvent: false,
              reminderOnMorningOfEvent: false
            };
            await updateCalendarSettings(newCalendarSettings);
            setCalendarSettingsId(user.email);
            setCalendarSettings(newCalendarSettings);
          }
        });
    };

    fetchCalendarSettings();
  }, [user, userSettings, calendarSettingsId]);

  useEffect(() => {
    if (!calendarSettingsId || !initialShouldFetchEvents || !!events) {
      return;
    }

    console.log("In fetch all calendar events effect", calendarSettingsId, events);

    setIsFetchingEvents(true);

    const setupSnapshot = async () => {
      try {
        console.log("Fetching all calendar events...");
        const results = await db
          .collection("calendar-settings-v2")
          .doc(calendarSettingsId)
          .collection("calendar-events")
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              console.log("Got no events for user:", user.email);
            }

            const events: Array<CalendarEvent> = snapshot.docs
              .filter((doc) => doc.id != null)
              .map((doc) => {
                const data = doc.data();
                return {
                  id: doc.id,
                  ...parseFirestoreDocument(data)
                } as CalendarEvent;
              });
            console.log("Events (from context)", events);
            setEvents(events);
          });
      } catch (error) {
        console.error("Error fetching calendar events", error);
      } finally {
        setIsFetchingEvents(false);
      }
    };
    setupSnapshot();
  }, [calendarSettingsId, initialShouldFetchEvents, events]);

  const handleCalendarDialogOpen = (event: CalendarEvent) => {
    setCurrentEvent(event);
    setCalendarDialogOpen(true);
  };

  const handleCalendarDialogClose = () => {
    setCurrentEvent(null);
    setCalendarDialogOpen(false);
  };

  const updateEventTimeById = async (
    eventId: string,
    newStart: Date,
    newEnd: Date,
    newAllDay: boolean
  ) => {
    try {
      const event = events.find((e) => e.id === eventId);
      const updatedEvent: CalendarEvent = {
        ...event,
        start: newStart,
        end: newEnd,
        allDay: newAllDay
      };
      const { id, ...updatedEventWithoutId } = updatedEvent;

      const eventRef = db
        .collection("calendar-settings-v2")
        .doc(calendarSettingsId)
        .collection("calendar-events")
        .doc(eventId);

      let firestoreEventPath = eventRef.path;

      await eventRef.set(updatedEventWithoutId, { merge: true });
      console.log("Updated event to", updatedEvent);

      // sending request in the background
      _sendEventUpdateToCustomer(updatedEvent, firestoreEventPath, CalendarOperationType.UPDATE);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const createOrUpdateEvent = async (event: CalendarEvent) => {
    let { id, ...eventWithoutId } = event;
    const isNewEvent = !id;

    try {
      const eventRef = db
        .collection("calendar-settings-v2")
        .doc(calendarSettingsId)
        .collection("calendar-events")
        .doc(id); // Note - id will originally be null for new events

      let firestoreEventPath = eventRef.path;

      await eventRef.set(eventWithoutId, { merge: true });
      console.log("Updated event", event);

      // sending request in the background
      const operationType = isNewEvent ? CalendarOperationType.NEW : CalendarOperationType.UPDATE;
      _sendEventUpdateToCustomer(event, firestoreEventPath, operationType);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteEvent = async (eventId: string) => {
    const eventResult = await db
      .collection("calendar-settings-v2")
      .doc(calendarSettingsId)
      .collection("calendar-events")
      .doc(eventId)
      .get();

    await db
      .collection("calendar-settings-v2")
      .doc(calendarSettingsId)
      .collection("calendar-events")
      .doc(eventId)
      .delete();

    _sendEventUpdateToCustomer(
      parseFirestoreDocument(eventResult.data()) as CalendarEvent,
      null,
      CalendarOperationType.DELETE
    );
  };

  const _sendEventUpdateToCustomer = (
    event: CalendarEvent,
    firestoreEventPath: string,
    calendarOperationType: CalendarOperationType
  ) => {
    let { id, ...eventWithoutId } = event;

    axios({
      url: "https://3d4160e0207f4972ebd3ba661f87b592.m.pipedream.net",
      method: "POST",
      data: {
        userEmail: user.email,
        event: eventWithoutId,
        calendarSettings: calendarSettings,
        firestoreEventPath: firestoreEventPath,
        operationType: calendarOperationType
      }
    });
  };

  const showSingleEventEditorModal = async (eventId: string) => {
    console.log("Show event modal called", eventId);
    const event = events.find((e) => e.id === eventId);
    if (!event) {
      enqueueSnackbar("Can't open event", { variant: "error" });
      return;
    }

    handleCalendarDialogOpen(event);
  };

  const showSingleEventEditorForDateRangeModal = async (start: Date, end: Date) => {
    console.log("Show event modal for range called", start, end);

    let event: Partial<CalendarEvent> = {
      start,
      end
    };

    handleCalendarDialogOpen(event as CalendarEvent);
  };

  const updateCalendarSettings = async (updatedCalendarSettings: Partial<CalendarSettings>) => {
    console.debug("Updating calendar settings to:", updatedCalendarSettings);
    await db
      .collection("calendar-settings-v2")
      .doc(user.email) // to make it easier, the first calendar will have the mail as ID. If we add more calendars, we can auto generate ids
      .set(updatedCalendarSettings, { merge: true }); // could be run in parallel eleswhere, merge to be safe
  };

  return (
    <>
      <CalendarEventDialog
        open={calendarDialogOpen}
        event={currentEvent}
        onClose={handleCalendarDialogClose}
        deleteEvent={deleteEvent}
        createOrUpdateEvent={createOrUpdateEvent}
      />
      <CalendarContext.Provider
        value={{
          events,
          initiateLoadEvents: () => setInitialShouldFetchEvents(true),
          createOrUpdateEvent,
          updateEventTimeById,
          deleteEvent,
          showSingleEventEditorModal,
          showSingleEventEditorForDateRangeModal,
          calendarSettings,
          updateCalendarSettings
        }}
      >
        {children}
      </CalendarContext.Provider>
    </>
  );
}

export { CalendarProvider, CalendarContext };
