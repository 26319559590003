import {
  Box,
  Typography,
  Grid,
  Container,
  Accordion,
  AccordionDetails,
  Button
} from "@material-ui/core";
import MuiAccordionSummary, { AccordionSummaryProps } from "@material-ui/core/AccordionSummary";
import { styled } from "@material-ui/styles";

import { Icon } from "@iconify/react";

import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-back-fill";

import UploadSingleFile from "components/__haim/Common/FileUpload/UploadSingleFile";
import "moment/locale/he"; // without this line, language is not imported
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "moment/locale/he"; // without this line, language is not imported
import { useTheme } from "@material-ui/core/styles";

import {
  customerTypePossibleValues,
  customerSeriousnessPossibleValues
} from "logic/CrmSpecifications/RealEstateCrmSpecifications";
import { getIcon } from "utils/getFileFormat";
import Label from "components/Label";
import { AgGridColumnProps } from "ag-grid-react";
import { useCallback, useEffect, useState } from "react";
import XLSX from "xlsx";
import LoadingScreen from "components/LoadingScreen";
import { useModal } from "mui-modal-provider";
import { ConfirmationDialog } from "components/__haim/Common/ConfirmationDialog";
import useFirestore from "hooks/useFirestore";
import useCrmSpecifications from "hooks/useCrmSpecifications";
import { metadataColumnNames } from "logic/CrmSpecifications/GenericCrmSpecifications";
import VideoModal from "../DashboardMain/VideoModal";
import VideoTutorialButton from "../Common/VideoTutorialButton";
import { getYoutubeLink } from "utils/Haim/utils";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)"
  },
  underline: {
    "&&&:before": {
      borderTop: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
}));

export default function UploadExplanationRealEstate() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const { currentDataColumns } = useCrmSpecifications();
  const relevantRealEstateColumns = currentDataColumns.filter(
    (c) => !metadataColumnNames.includes(c.field)
  );

  const { palette } = useTheme();

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md="auto">
          <VideoTutorialButton
            fontColor={palette.text.primary}
            backgroundColor={"#dae9f7"}
            tutorialName="סרטון הדרכה - "
            url={getYoutubeLink("wZnytYoffkw")}
            endIcon={<img src="/static/_haim/bmby.png" height={"13px"} />}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <VideoTutorialButton
            tutorialName="סרטון הדרכה - "
            url={getYoutubeLink("qWmc5euegTE")}
            endIcon={<img src="/static/_haim/nadlan1.png" height={"17px"} />}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <VideoTutorialButton
            fontColor={palette.text.primary}
            backgroundColor="#cffccc"
            tutorialName="סרטון הדרכה - אקסל"
            url={getYoutubeLink("1M4fM_S_JD8")}
            endIcon={getIcon("file_type_excel")}
          />
        </Grid>
      </Grid>

      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
          <Typography variant="subtitle1">{"איזה מידע אפשר לייבא?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{ mb: 1 }}>
            עמודות:
          </Typography>

          {relevantRealEstateColumns
            .filter((c) => c.headerName != "")
            .map((column) => (
              <Label
                key={column.headerName}
                variant={isLight ? "ghost" : "filled"}
                color={"warning"}
                sx={{ mx: 0.5 }}
              >
                {column.headerName}
              </Label>
            ))}

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            ערכים אפשריים לעמודות:
          </Typography>

          <Grid container spacing={0.5}>
            <Grid item xs="auto">
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                - מספר טלפון:
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                מספר בפורמט בין לאומי, או בפורמט ישראלי. לדוגמה:
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {["0527485568", "972527485568+"].map((value) => (
                <Label
                  key={value}
                  variant={isLight ? "ghost" : "filled"}
                  color={"info"}
                  sx={{ mx: 0.5 }}
                >
                  {value}
                </Label>
              ))}
            </Grid>
          </Grid>

          <Grid container spacing={0.5} sx={{ mt: 1 }}>
            <Grid item xs="auto">
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                - סוג לקוח:
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {customerTypePossibleValues
                .filter((v) => v != "")
                .map((value) => (
                  <Label
                    key={value}
                    variant={isLight ? "ghost" : "filled"}
                    color={"info"}
                    sx={{ mx: 0.5 }}
                  >
                    {value}
                  </Label>
                ))}
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs="auto">
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                - רצינות:
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {customerSeriousnessPossibleValues
                .filter((v) => v != "")
                .map((value) => (
                  <Label
                    key={value}
                    variant={isLight ? "ghost" : "filled"}
                    color={"info"}
                    sx={{ mx: 0.5 }}
                  >
                    {value}
                  </Label>
                ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
