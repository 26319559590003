// material
import { enUS, heIL } from "@material-ui/core/locale";
import { useTranslation } from "react-i18next";
import heLocale from "@fullcalendar/core/locales/he";
import enLocale from "@fullcalendar/core/locales/en-gb";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "עברית",
    direction: "rtl",
    value: "he",
    systemValue: heIL,
    dateLocale: "he-HE",
    fullCalendarLocale: heLocale,
    icon: "/static/icons/ic_flag_il.svg"
  },
  {
    label: "English",
    direction: "ltr",
    value: "en",
    systemValue: enUS,
    dateLocale: "en-US",
    fullCalendarLocale: enLocale,
    icon: "/static/icons/ic_flag_en.svg"
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  if (currentLang.value != langStorage) {
    handleChangeLanguage(currentLang.value);
  }
  return {
    i18n,
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
