import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import useLocales from "../../../hooks/useLocales";
import { useModal } from "mui-modal-provider";
import { sleep } from "utils/Haim/utils";
import { CalendarEvent } from "../../../@types/calendar";
import { DialogAnimate } from "components/animate";
import { CalendarForm } from "components/_dashboard/calendar";
import { CalendarContext } from "contexts/CalendarContext";
import useFirestore from "hooks/useFirestore";
import { useTranslation } from "react-i18next";

type Props = DialogProps & {
  event: CalendarEvent;
  createOrUpdateEvent: (event: CalendarEvent) => Promise<boolean>;
  deleteEvent: (eventId: string) => Promise<void>;
};

export const CalendarEventDialog: React.FC<Props> = ({
  event,
  createOrUpdateEvent,
  deleteEvent,
  onClose,
  open,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog {...props} open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <DialogTitle>
        {event && (!!event.id ? t("calendarPage.editEvent") : t("calendarPage.addEvent"))}
      </DialogTitle>
      <CalendarForm
        event={event}
        onUpdate={createOrUpdateEvent}
        onDelete={deleteEvent}
        onCancel={onClose as VoidFunction}
      />
    </Dialog>
  );
};
