import { Card, Box, Container, Typography } from "@material-ui/core";
// material
import { styled } from "@material-ui/core/styles";
import AuthFirebaseSocials from "../../components/authentication/AuthFirebaseSocial";
// components
import Page from "../../components/Page";
// hooks
import useAuth from "../../hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  return (
    <RootStyle title="Login">
      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h4" gutterBottom sx={{ mb: 1, textAlign: "center" }}>
            Sign in to Webot
          </Typography>

          <AuthFirebaseSocials />

          <img alt="login" src="/static/illustrations/illustration_register.png" />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
