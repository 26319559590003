import { Icon } from "@iconify/react";
import roundViewDay from "@iconify/icons-ic/round-view-day";
import roundViewWeek from "@iconify/icons-ic/round-view-week";
import roundViewAgenda from "@iconify/icons-ic/round-view-agenda";
import roundViewModule from "@iconify/icons-ic/round-view-module";
import arrowIosBackFill from "@iconify/icons-eva/arrow-ios-back-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
// material
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Stack,
  Button,
  Tooltip,
  Typography,
  IconButton,
  ToggleButton
} from "@material-ui/core";
// utils
import { fDate } from "../../../utils/formatTime";
//
import { MHidden } from "../../@material-extend";
import { CalendarView } from "../../../@types/calendar";
import useSettings from "hooks/useSettings";

import moment from "moment-timezone";
import "moment/locale/he"; // without this line, language is not imported
import { useTranslation } from "react-i18next";
import useLocales from "hooks/useLocales";

// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  { value: "dayGridMonth", label: "Month", icon: roundViewModule },
  { value: "timeGridWeek", label: "Week", icon: roundViewWeek },
  { value: "timeGridDay", label: "Day", icon: roundViewDay },
  { value: "listWeek", label: "Agenda", icon: roundViewAgenda }
] as const;

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(3, 0),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    padding: theme.spacing(1.75, 3),
    justifyContent: "space-between"
  }
}));

// ----------------------------------------------------------------------

type CalendarToolbarProps = {
  date: Date;
  view: CalendarView;
  onToday: VoidFunction;
  onNextDate: VoidFunction;
  onPrevDate: VoidFunction;
  onChangeView: (view: CalendarView) => void;
};

export default function CalendarToolbar({
  date,
  view,
  onNextDate,
  onPrevDate,
  onToday,
  onChangeView
}: CalendarToolbarProps) {
  const { themeDirection } = useSettings();
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  const getHeaderDateStr = () => {
    if (view === "listWeek" || view === "timeGridWeek") {
      const startOfWeekDate = moment(date).startOf("week").toDate();
      const endOfWeekDate = moment(date).endOf("week").toDate();
      const isSameMonth = startOfWeekDate.getMonth() === endOfWeekDate.getMonth();
      const startOfWeekDay = startOfWeekDate.toLocaleDateString(currentLang.dateLocale, {
        day: "numeric"
      });
      const endOfWeekDay = endOfWeekDate.toLocaleDateString(currentLang.dateLocale, {
        day: "numeric"
      });
      if (isSameMonth) {
        return `${startOfWeekDay}-${endOfWeekDay} ${startOfWeekDate.toLocaleDateString(
          currentLang.dateLocale,
          { month: "long", year: "numeric" }
        )}`;
      } else {
        return `${startOfWeekDay} ${startOfWeekDate.toLocaleDateString(currentLang.dateLocale, {
          month: "long"
        })} - ${endOfWeekDay} ${endOfWeekDate.toLocaleDateString(currentLang.dateLocale, {
          month: "long",
          year: "numeric"
        })}`;
      }
    } else if (view === "dayGridMonth") {
      return date.toLocaleDateString(currentLang.dateLocale, {
        year: "numeric",
        month: "long"
      });
    } else {
      return date.toLocaleDateString(currentLang.dateLocale, {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    }
  };

  return (
    <RootStyle>
      {/* <MHidden width="smDown"> */}
      <Stack direction="row" spacing={1.5}>
        {VIEW_OPTIONS.map((viewOption) => (
          <Tooltip key={viewOption.value} title={viewOption.label}>
            <ToggleButton
              value={view}
              selected={viewOption.value === view}
              onChange={() => onChangeView(viewOption.value)}
              sx={{ width: 32, height: 32, padding: 0 }}
            >
              <Icon icon={viewOption.icon} width={20} height={20} />
            </ToggleButton>
          </Tooltip>
        ))}
      </Stack>
      {/* </MHidden> */}

      <Typography variant="h5" sx={{ my: { xs: 1, sm: 0 } }}>
        {getHeaderDateStr()}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={onPrevDate}>
          <Icon
            icon={themeDirection == "rtl" ? arrowIosForwardFill : arrowIosBackFill}
            width={18}
            height={18}
          />
        </IconButton>

        <Button size="small" color="error" variant="contained" onClick={onToday} sx={{ mx: 0.5 }}>
          {t("calendarPage.today")}
        </Button>

        <IconButton onClick={onNextDate}>
          <Icon
            icon={themeDirection == "rtl" ? arrowIosBackFill : arrowIosForwardFill}
            width={18}
            height={18}
          />
        </IconButton>
      </Box>
    </RootStyle>
  );
}
