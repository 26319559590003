import firebase from "firebase/compat";

export type Organization = {
  id: string;
  admins: string[];
  users: string[];
  name: string;
  logo: string;
  featureFlagsV2?: string[];
};

export const organizationConverter: firebase.firestore.FirestoreDataConverter<Organization> = {
  toFirestore(organization) {
    return organization;
  },
  fromFirestore(snapshot, options) {
    const { id } = snapshot;
    const data = snapshot.data(options);

    return { ...data, id } as Organization;
  }
};
