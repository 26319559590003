import googleFill from "@iconify/icons-eva/google-fill";
import { Icon } from "@iconify/react";
// material
import { Button, Stack, Typography } from "@material-ui/core";
import { loginWithGoogle } from "../../firebaseConfig";

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const handleLoginGoogle = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={handleLoginGoogle}
        >
          <Stack direction="row">
            <Icon icon={googleFill} color="#DF3E30" height={24} />
            <Typography variant="body2" sx={{ color: "#DF3E30", ml: 2 }}>
              Sign in with Google
            </Typography>
          </Stack>
        </Button>
        {/* <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={handleLoginFaceBook}
        >
          <Stack direction="row">
            <Icon icon={facebookFill} color="#1877F2" height={24} />
            <Typography variant="body2" sx={{ color: '#1877F2', ml: 2 }}>
              Sign in with Facebook
            </Typography>
          </Stack>
        </Button> */}
      </Stack>
    </>
  );
}
