import moment from "moment";
import firebase from "firebase/compat";
import { db } from "firebaseConfig";
import { listingConverter } from "../../@types/listings/listing";
import type { SyntheticEvent } from "react";
import type { Listing } from "../../@types/listings/listing";
import type { AnalyticsType } from "components/_dashboard/listings/table/core/ListingAnalyticsCard";
import type { ListingTabType } from "components/_dashboard/listings/table/filters/ListingTableFilters";

type FirestoreListingQueryOptions = {
  userEmail: string;
  organizationId: string | null;
  listingTabType: ListingTabType;
};

export function getFirestoreListingQuery({
  userEmail,
  organizationId,
  listingTabType
}: FirestoreListingQueryOptions): firebase.firestore.Query<Listing> {
  let firestoreListingQuery = db
    .collection("real-estate-listings")
    .withConverter(listingConverter)
    .orderBy("createdTime", "desc");

  switch (listingTabType) {
    case "myOrganization":
      firestoreListingQuery = firestoreListingQuery
        .where("createdByOrganization", "==", organizationId ?? "")
        .where("visibility", "in", ["organization", "public"]);
      break;
    case "myListings":
      firestoreListingQuery = firestoreListingQuery.where("owners", "array-contains", userEmail);
      break;
    case "saved":
      firestoreListingQuery = firestoreListingQuery
        .where("userFavorites", "array-contains", userEmail)
        .where("visibility", "in", ["organization", "public"]);
      break;
    default:
      firestoreListingQuery = firestoreListingQuery.where("visibility", "==", "public");
      break;
  }

  return firestoreListingQuery;
}

export async function checkListingAliasAvailability(listingAlias: string): Promise<boolean> {
  const { empty } = await db
    .collectionGroup("listingData")
    .where("listingAlias", "==", listingAlias)
    .limit(1)
    .get();

  return empty;
}

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(firstDate: Date, secondDate: Date): number {
  const firstDateUTC = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());

  const secondDateUTC = Date.UTC(
    secondDate.getFullYear(),
    secondDate.getMonth(),
    secondDate.getDate()
  );

  return Math.floor((secondDateUTC - firstDateUTC) / MS_PER_DAY);
}

export function isListingExclusive(
  exclusivity: firebase.firestore.Timestamp,
  target?: Date
): boolean {
  const targetDate = moment(target ?? new Date()).format("YYYY-MM-DD");
  const exclusivityDate = moment(exclusivity.toDate()).format("YYYY-MM-DD");

  return exclusivityDate >= targetDate;
}

export function isListingNearExclusivity(exclusivity: firebase.firestore.Timestamp): boolean {
  const now = new Date();
  const exclusivityDate = exclusivity.toDate();

  const daysDiff = dateDiffInDays(now, exclusivityDate);

  return daysDiff >= 0 && daysDiff <= 30;
}

export function typeIsOneOfAnalyticsTypes(type: AnalyticsType, types: AnalyticsType[]): boolean {
  return types.includes(type);
}

export function isCalculatingSoldFee(type: AnalyticsType): boolean {
  return typeIsOneOfAnalyticsTypes(type, [
    "soldAllFees",
    "soldSellerFee",
    "soldBuyerFee",
    "soldExternalBuyerFee"
  ]);
}

export function isValidListingAlias(alias: string): null | string {
  if (!alias) return "listingAlias";
  if (alias.length < 4) return "listingAliasTooShort";
  if (alias.length > 15) return "listingAliasTooLong";
  if (!/^[\p{L}\d_]+$/u.test(alias)) return "listingAliasInvalid";
  if (!/\p{L}/u.test(alias)) return "listingAliasNoLetter";

  return null;
}

export function isListingNotRecentlyUpdated(
  prevTimestamp: firebase.firestore.Timestamp,
  currentTimestamp: firebase.firestore.Timestamp
) {
  if (!currentTimestamp) return true;

  const isNotRecentlyUpdated = prevTimestamp ? currentTimestamp.isEqual(prevTimestamp) : false;

  return isNotRecentlyUpdated;
}

export function preventBubbling(callback: (...args: unknown[]) => unknown) {
  return (e: SyntheticEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    callback();
  };
}

export function shareLink({ title, url }: { title: string; url: string }) {
  return (): void => void navigator.share({ title, url });
}

export function getDefaultStartDate(): string {
  return moment().subtract(30, "days").format("YYYY-MM-DD");
}
