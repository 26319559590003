import { Stack, useTheme, Typography } from "@material-ui/core";

import useAuth from "hooks/useAuth";
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import useFirestore from "hooks/useFirestore";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumberInputHelperComponent from "../Common/PhoneNumberInputHelperComponent";
import { toInternationalFormat } from "utils/Haim/phoneNumbersUtils";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";

type UserRoleInputProps = {
  onboarding?: boolean;
  onLoadingChange: Dispatch<SetStateAction<boolean>>;
  fireSnackbarOpen?: (message?: string) => void;
  setCanMoveToNextStep?: Dispatch<SetStateAction<boolean>>;
};

export function PhoneNumberInput({
  onboarding,
  onLoadingChange,
  fireSnackbarOpen,
  setCanMoveToNextStep
}: UserRoleInputProps) {
  const { user } = useAuth();
  const { updateUserSettings, userSettings } = useFirestore();
  const theme = useTheme();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    userSettings?.ownerPhoneNumber ?? null
  );

  useEffect(() => {
    if (phoneNumber !== userSettings?.ownerPhoneNumber) {
      setPhoneNumber(userSettings?.ownerPhoneNumber);
    }
  }, [userSettings?.ownerPhoneNumber]);

  useEffect(() => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      setCanMoveToNextStep?.(true);
    } else {
      setCanMoveToNextStep?.(false);
    }
  }, [phoneNumber]);

  const onChangePhoneNumber = (value: string) => {
    setPhoneNumber(value);
  };

  const onBlurPhoneNumber = async () => {
    if (phoneNumber && phoneNumber.length > 0 && isValidPhoneNumber(phoneNumber)) {
      var ownerPhoneNumber = toInternationalFormat(phoneNumber) ?? phoneNumber;
      await updateUserSettings({ ownerPhoneNumber: ownerPhoneNumber });
    }
  };

  return (
    <Stack gap={1} maxWidth={"250px"}>
      {onboarding && (
        <Typography variant="body1" fontWeight="bold" sx={{ pb: 2 }}>
          {t("onboarding.PhoneNumberInput.title")}
        </Typography>
      )}
      <PhoneInput
        defaultCountry="IL"
        name="phoneInput"
        value={phoneNumber}
        onChange={onChangePhoneNumber}
        onBlur={onBlurPhoneNumber}
        rules={{ required: true, validate: isValidPhoneNumber }}
        inputComponent={PhoneNumberInputHelperComponent}
        numberInputProps={{
          size: "small",
          "aria-errormessage": "מספר טלפון אינו תקין",
          error: !!phoneNumber && phoneNumber.length > 0 && !isValidPhoneNumber(phoneNumber)
        }}
      />
    </Stack>
  );
}
