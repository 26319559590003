import { BaseDocument, UserSettings } from "../@types/firestore";
import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions
} from "@firebase/firestore-types";
import { DocumentData, Timestamp, WithFieldValue, collection } from "firebase/firestore";
import "firebase/compat/firestore";
import { db } from "../firebaseConfig";

// This class is similar to the firestoreConverter in the server code.

export const usersCollection = db
  .collection("dashboard-users-v2")
  .withConverter(GenericConverter<UserSettings>());

// A generic converter for any object that has an id field, that also converts the object to the right type
export function GenericConverter<
  T extends BaseDocument | { id: string; updatedTime: any; createdTime: any }
>(): FirestoreDataConverter<T> {
  return {
    toFirestore(obj: T) {
      const { id, updatedTime, ...data } = obj; // removing id since it's not part of the data, and updatedTime since it is automatically updated
      return convertJsDatesToFirestoreDates(data);
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<T, T>, options: SnapshotOptions): T {
      const { id } = snapshot;
      const data = convertFirestoreDatesToJsDates(snapshot.data()) as T;

      return { ...data, id };
    }
  };
}

export function convertFirestoreDatesToJsDates(obj: DocumentData) {
  // Converting firestore timestamp to Date
  const newObj = Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k, v instanceof Timestamp ? v.toDate() : v])
  );

  return newObj;
}

export function convertJsDatesToFirestoreDates(obj: any) {
  // Converting JS dates to firestore timestamps
  const newObj = Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k, v instanceof Date ? Timestamp.fromDate(v) : v])
  );

  return newObj;
}
