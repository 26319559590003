import { useState, createContext, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import firebase from "firebase/compat";
import moment from "moment";
import { db } from "firebaseConfig";
import { sleep } from "utils/Haim/utils";
import { useModal } from "mui-modal-provider";
import { removeUndefined, generateMetadataFromContact } from "utils/Haim/firestoreUtils";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "components/__haim/Common/ConfirmationDialog";
import type { ReactNode } from "react";
import type { RealEstateContact } from "../@types/contact";
import type { AppContextProps } from "../@types/app";

const AppContext = createContext<AppContextProps | null>(null);

type AppProviderProps = {
  children: ReactNode;
};

function AppProvider({ children }: AppProviderProps): JSX.Element {
  const [allContacts, setAllContacts] = useState<RealEstateContact[]>([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [firstContactLoaded, setFirstContactLoaded] = useState(false);

  const { user, isAuthenticated } = useAuth();

  const { t: translate } = useTranslation();

  const { showModal } = useModal();

  useEffect(() => {
    if (isAuthenticated) return;

    setAllContacts([]);
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user?.email) return;
    if (!firstContactLoaded) return;

    const getContacts = async () => {
      setIsLoadingContacts(true);

      const userIdToken = await user?.getIdToken();

      console.log("Fetching contacts...");

      const functionsURL = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/getContacts`;

      try {
        const contactResponse = await fetch(functionsURL, {
          headers: { authorization: userIdToken }
        });

        const rawContacts = await contactResponse.json();

        let contactsData = rawContacts.map((data) => {
          data.callerPhoneNumber = data.id;
          data.contactOriginAdditionalInfo = data.contactOriginAdditionalInfo ?? user.email;
          data._metadata = data._metadata ?? generateMetadataFromContact(user?.email, data.id);

          const customerBudget = data.customerBudget;

          // Fixing customer budget column, which historically has been a string
          if (typeof customerBudget === "string") {
            const parsed = parseInt(customerBudget?.replace(/,/g, ""));
            if (!isNaN(parsed)) data.customerBudget = parsed;
          }

          return data;
        }) as RealEstateContact[];

        contactsData = contactsData.sort((a, b) => (a.createdTime < b.createdTime ? 1 : -1));

        setAllContacts(contactsData);
        setIsLoadingContacts(false);
      } catch (err) {
        console.error(err);

        showModal(ConfirmationDialog, {
          title: translate(`contactsPage.error.failedToGetContacts`),
          description: translate("contactsPage.error.tryRefreshingThePage")
        });

        setIsLoadingContacts(false);
      }
    };

    void getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, firstContactLoaded]);

  const getAllContacts = () => {
    setFirstContactLoaded(true);

    return allContacts;
  };

  const updateContact = async (contactId: string, data: any, enforceCreateTime?: boolean) => {
    removeUndefined(data);

    const { _metadata, createdTime, updatedTime, ...dataToUpdate } = data;

    const existingContactInUserContacts = allContacts.find((c) => c.id === contactId);

    await db
      .collection("dashboard-users-v2")
      .doc(user.email)
      .collection("contacts")
      .doc(contactId)
      .set(dataToUpdate, { merge: true });

    let updatedContacts: RealEstateContact[] = [];

    const currentUpdatedTime = moment().toISOString();

    if (existingContactInUserContacts) {
      updatedContacts = allContacts.map((contact) =>
        contact.id === contactId
          ? {
              ...contact,
              ...dataToUpdate,
              createdTime: createdTime,
              updatedTime: currentUpdatedTime
            }
          : contact
      );
    } else {
      const newContact = {
        id: contactId,
        callerPhoneNumber: contactId,
        ...dataToUpdate,
        _metadata: generateMetadataFromContact(user?.email, contactId)
      };

      if (enforceCreateTime && !newContact.createdTime) {
        newContact.createdTime = currentUpdatedTime;
        newContact.updatedTime = currentUpdatedTime;
      }

      updatedContacts = [newContact, ...allContacts];
    }

    setAllContacts(updatedContacts);
  };

  const batchUpdate = async (
    refAndUpdates: Map<
      firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
      Object
    >
  ) => {
    console.log("Running a firestore batch update...");
    const batches: Array<firebase.firestore.WriteBatch> = [];
    let batch: firebase.firestore.WriteBatch = null;
    let index = 0;
    for (let [key, value] of refAndUpdates) {
      if (index === 0) {
        console.log("Creating a new firestore batch");
        batch = db.batch();
        batches.push(batch);
      }

      const { createdTime, updatedTime, ...dataWithoutTimestamp } = value as RealEstateContact;

      batch.set(key, dataWithoutTimestamp, { merge: true }); // Set let us create if not exists

      index += 1;
      if (index === 499) {
        console.log("Pushing batch to commit...");
        index = 0;
        await sleep(50);
      }
    }

    const commitPromises = batches.map((b) => b.commit());

    await Promise.all(commitPromises);

    const contactsMapToArray = Array.from(refAndUpdates, ([{ id }, value]) => ({ id, ...value }));

    const currentUpdatedTime = moment().toISOString();

    const newUploadedContacts = contactsMapToArray
      .filter((newContact) => !allContacts.some((contact) => contact.id === newContact.id))
      .map((newContact) => ({
        ...newContact,
        updatedTime: currentUpdatedTime,
        callerPhoneNumber: newContact.id,
        _metadata: generateMetadataFromContact(user?.email, newContact.id)
      }));

    const newUpdatedContacts = allContacts.map((oldContact) => {
      const newContactUpdate = contactsMapToArray.find(
        (newContact) => newContact.id === oldContact.id
      );

      return newContactUpdate
        ? { ...oldContact, ...newContactUpdate, updatedTime: currentUpdatedTime }
        : oldContact;
    });

    const newAllContacts = [...newUpdatedContacts, ...newUploadedContacts] as RealEstateContact[];

    setAllContacts(newAllContacts);
  };

  const batchDelete = async (
    refs: Array<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>
  ) => {
    console.log("Running a firestore batch delete...", refs);

    const batches: Array<firebase.firestore.WriteBatch> = [];

    let batch: firebase.firestore.WriteBatch = null;
    let index = 0;

    for (let key of refs) {
      if (index === 0) {
        console.log("Creating a new firestore batch");
        batch = db.batch();
        batches.push(batch);
      }

      batch.delete(key);

      index += 1;

      if (index === 499) {
        console.log("Pushing batch to commit...");
        index = 0;
        await sleep(50);
      }
    }

    const commitPromises = batches.map((b) => b.commit());

    await Promise.all(commitPromises);

    const updatedContacts = allContacts.filter(
      (contact) => !refs.some((ref) => ref.id === contact.id)
    );

    setAllContacts(updatedContacts);
  };

  return (
    <AppContext.Provider
      value={{
        allContacts,
        isLoadingContacts,
        batchUpdate,
        batchDelete,
        updateContact,
        setAllContacts,
        getAllContacts
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
