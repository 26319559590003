import { useRef, useEffect, useCallback } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import type { Dispatch, SetStateAction, CSSProperties } from "react";

type SoldConfettiAnimationProps = {
  open: boolean;
  onConfettiOpen?: Dispatch<SetStateAction<boolean>>;
};

export function SoldConfettiAnimation({ open, onConfettiOpen }: SoldConfettiAnimationProps) {
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

  useEffect(() => {
    if (!open) return;

    fire();
    onConfettiOpen(false);
  }, [open, fire, onConfettiOpen]);

  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
}

const canvasStyles: CSSProperties = {
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 999999,
  position: "fixed",
  pointerEvents: "none"
};
