import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { DialogContent, Grid } from "@material-ui/core";
import { useState } from "react";
import { VideoPlayer } from "../../VideoPlayer";

export type VideoModalProps = {
  url: string;
};

export default function VideoModal({ url }: VideoModalProps) {
  const [modalOpen, setModalOpen] = useState(true);

  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg">
      <DialogContent
        sx={{
          display: "flex",
          width: { lg: 700, sm: 600, xs: "100%" },
          height: { lg: 500, sm: 460, xs: 360 }
        }}
      >
        <VideoPlayer url={url} />
      </DialogContent>
    </Dialog>
  );
}
