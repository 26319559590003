import { EventInput } from "@fullcalendar/common";
import { PhoneNumber } from "libphonenumber-js/types";

export type CalendarView = "dayGridMonth" | "timeGridWeek" | "timeGridDay" | "listWeek";

// TODO remove after move to context?
export type CalendarState = {
  isLoading: boolean;
  error: boolean;
  events: EventInput[];
  isOpenModal: boolean;
  selectedEventId: null | string;
  selectedRange: null | { start: Date; end: Date };
};

export type CalendarContextProps = {
  events: CalendarEvent[];
  initiateLoadEvents: () => void;
  createOrUpdateEvent: (event: CalendarEvent) => Promise<boolean>;
  updateEventTimeById: (
    eventId: string,
    newStart: Date,
    newEnd: Date,
    newAllDay: boolean
  ) => Promise<boolean>;
  deleteEvent: (eventId: string) => Promise<void>;
  showSingleEventEditorModal: (eventId?: string) => void;
  calendarSettings: CalendarSettings;
  showSingleEventEditorForDateRangeModal: (start: Date, end: Date) => void;
  updateCalendarSettings: (updatedCalendarSettings: Partial<CalendarSettings>) => Promise<void>;
  // currentState: CalendarState;
  // setCurrentState: (state: CalendarState) => void;
};

export type CalendarSettings = {
  admin: string;
  owners: string[];
  calendarName: string;
  ownerFullName: string;
  ownerPhoneNumberInternational: string;
  reminderHourBeforeEvent: boolean;
  reminderOnMorningDayBefore: boolean;
  reminderOnEveningBeforeEvent: boolean;
  reminderOnMorningOfEvent: boolean;
};

export type CalendarEventAttendee = {
  firstName?: string;
  lastName?: string;
  phoneNumberObj?: PhoneNumber;
  phoneNumberInternational?: string;
};

export type CalendarEvent = {
  id: string;
  title: string;
  description: string;
  textColor: string;
  allDay?: boolean;
  start: Date;
  end: Date;
  attendees?: CalendarEventAttendee[];
};

export enum CalendarOperationType {
  NEW = "NEW",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  REMINDER = "REMINDER"
}
