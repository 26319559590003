import { db } from "firebaseConfig";
import { DateRangeFilter } from "utils/listings/table";
import { Query } from "@firebase/firestore-types";

const retry = require("async-retry");

export async function getWebotServerDomain(): Promise<string> {
  let webotServerDomain = (await fetchConfig()).currentDomainServer;
  console.log("Got Webot server domain:", webotServerDomain);
  return webotServerDomain;
}

export type WebotConfig = {
  currentDomainServer: string;
};

export async function fetchConfig(): Promise<WebotConfig> {
  return await retry(
    async () => {
      console.log("Fetching webot config...");
      const response = await fetch(
        "https://webotserver.blob.core.windows.net/public/webotConfig.json",
        { cache: "no-cache" } // To get the latest version
      );
      const data = await response.json();
      return data;
    },
    {
      retries: 5,
      minTimeout: 1000
    }
  );
}

const _getCallsDataForNumber = async (
  phoneNumber: string,
  dateFrom: Date | DateRangeFilter,
  fromPhoneNumber: string
) => {
  const isUsingDateRange = !(dateFrom instanceof Date);

  let callsQuery: Query = db.collection(`payCall-settings/${phoneNumber}/calls-summary`);

  if (isUsingDateRange) {
    const { min, max } = dateFrom;

    const parsedStartDate = min ? new Date(min) : null;
    const parsedEndDate = max ? new Date(max) : null;

    if (parsedStartDate) callsQuery = callsQuery.where("createdTime", ">=", parsedStartDate);
    if (parsedEndDate) callsQuery = callsQuery.where("createdTime", "<=", parsedEndDate);
  } else callsQuery = callsQuery.where("createdTime", ">=", dateFrom);

  if (fromPhoneNumber) {
    callsQuery = callsQuery.where("callerNumberInternational", "==", fromPhoneNumber);
  }

  const results = await callsQuery.get();

  const parsedDateFrom =
    dateFrom instanceof Date ? dateFrom : dateFrom.min ? new Date(dateFrom.min) : null;

  console.log(
    `[${phoneNumber}][${parsedDateFrom?.toISOString()}] Got results (inner): ${results.docs.length}`
  );
  return results;
};

export const getCallsDataForNumber = async (
  phoneNumber: string,
  dateFrom: Date | DateRangeFilter,
  fromPhoneNumber: string
) => {
  const parsedDateFrom =
    dateFrom instanceof Date ? dateFrom : dateFrom.min ? new Date(dateFrom.min) : null;

  console.log("Reading for number", phoneNumber, "from date", parsedDateFrom?.toISOString());
  const results = await _getCallsDataForNumber(phoneNumber, dateFrom, fromPhoneNumber);
  console.log(`[${phoneNumber}][${parsedDateFrom?.toISOString()}] Got results: ${results.size}`);
  return results.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id
  }));
};

const getSettingsForUser = async (userMail: String) => {
  const result = await db.doc(`dashboard-users-v2/${userMail}`).get();

  return result.data();
};

export const getCallsOfUser = async (
  userMail: string,
  dateFrom: Date | DateRangeFilter,
  virtualNumbers: string[] = null,
  fromPhoneNumber?: string
) => {
  if (virtualNumbers === null) {
    const settings = await getSettingsForUser(userMail);
    virtualNumbers = settings?.virtualNumbers || [];
  }
  let calls = [];
  for (const virtualNumber of virtualNumbers) {
    const allCalls = await getCallsDataForNumber(virtualNumber, dateFrom, fromPhoneNumber);
    console.log(`[${userMail}] Got ${allCalls.length} calls for virtual number ${virtualNumber}`);
    calls = calls.concat(allCalls);
  }

  console.log(`[${userMail}] Total: ${calls.length} calls.`);
  return calls;
};
