// @ts-nocheck
import { Button, useTheme } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import useLocales from "hooks/useLocales";
import { useNavigate } from "react-router";
import { useModal } from "mui-modal-provider";
import VideoModal from "../DashboardMain/VideoModal";

export type VideoTutorialButtonProps = {
  tutorialName: string;
  url: string;
  endIcon?: JSX.Element;
  backgroundColor?: string;
  fontColor?: string;
};

export default function VideoTutorialButton({
  tutorialName,
  url,
  endIcon = null,
  backgroundColor = "#01274a",
  fontColor = null
}: VideoTutorialButtonProps) {
  const { showModal } = useModal();

  if (!fontColor) {
    fontColor = "white";
  }

  return (
    <>
      <Button
        onClick={() => {
          showModal(() => <VideoModal url={url} />);
        }}
        style={{
          color: fontColor,
          backgroundColor: backgroundColor
        }}
        sx={{
          boxShadow: 0
        }}
        variant="contained"
        endIcon={endIcon}
      >
        {tutorialName}
      </Button>
    </>
  );
}
