import Lottie from "react-lottie";
import { Box } from "@material-ui/system";
import { Stack } from "@material-ui/core";

import PendingIcon from "@material-ui/icons/Pending";
import InProgressAnim from "assets/lottie/in progress.json";
import CompletedAnim from "assets/lottie/completed.json";
import FailedAnim from "assets/lottie/failed.json";

export default function SendStatusCell({ status }) {
  const DefaultAnimOptions = {
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid"
    }
  };

  switch (status) {
    case "pending":
      return (
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ display: "flex", color: "secondary.main" }}
        >
          <PendingIcon sx={{ mx: "4px" }} />
          <div>ממתין לשליחה</div>
        </Stack>
      );
    case "in progress":
      return (
        <Stack direction="row" alignItems={"center"} sx={{ display: "flex", color: "info.main" }}>
          <Lottie
            style={{ display: "flex", width: "32px", padding: "5px" }}
            options={{
              loop: true,
              animationData: InProgressAnim,
              DefaultAnimOptions
            }}
          />
          <div>בתהליך לשליחה</div>
        </Stack>
      );
    case "failed":
      return (
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ display: "flex", color: "warning.main" }}
        >
          <Lottie
            style={{ display: "flex", width: "32px", padding: "5px" }}
            options={{
              loop: false,
              animationData: FailedAnim,
              DefaultAnimOptions
            }}
          />
          <div>שגיאה</div>
        </Stack>
      );
    case "completed":
      return (
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ display: "flex", color: "success.main" }}
        >
          <Lottie
            style={{ display: "flex", width: "32px", padding: "1px" }}
            options={{
              loop: false,
              animationData: CompletedAnim,
              DefaultAnimOptions
            }}
          />
          <div>הודעה נשלחה</div>
        </Stack>
      );
    default:
      break;
  }
}
