// hooks
import useAuth from "../hooks/useAuth";
//
import { MAvatar } from "./@material-extend";
import { MAvatarProps } from "./@material-extend/MAvatar";
import createAvatar from "../utils/createAvatar";
import useFirestore from "hooks/useFirestore";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { userSettings } = useFirestore();

  const photoURL = userSettings?.photoURL;
  const ownerFullName = userSettings?.ownerFullName;

  return (
    <MAvatar
      src={photoURL}
      alt={ownerFullName}
      color={photoURL ? "default" : createAvatar(ownerFullName).color}
      {...other}
    >
      {createAvatar(ownerFullName).name}
    </MAvatar>
  );
}
