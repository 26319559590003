import { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

// See https://stackoverflow.com/questions/60909788/how-to-use-material-ui-textfield-with-react-phone-number-input
const PhoneNumberInputHelperComponent = (props, ref) => {
  const { t } = useTranslation();

  return (
    <TextField
      {...props}
      required
      inputRef={ref}
      fullWidth
      size="small"
      label={t("settingsPage.phoneNumberLabel")}
      variant="outlined"
      name="phoneInput"
      id="phoneInput"
    />
  );
};
export default forwardRef(PhoneNumberInputHelperComponent);
