import { Tooltip } from "@material-ui/core";
import { LocalPhone, PhoneMissed, Cancel, PhoneDisabled } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

export default function CallstatusCell({ result }) {
  const { t: translate } = useTranslation();

  let statusIcon, title;
  switch (result) {
    case "ANSWER":
      statusIcon = <LocalPhone color={"success"} />;
      title = translate("call.ANSWER");
      break;
    case "BUSY":
      statusIcon = <PhoneMissed color={"warning"} />;
      title = translate("call.BUSY");
      break;
    case "NOANSWER":
    case "IVR_HANGUP":
    case "CONGESTION":
      statusIcon = <PhoneDisabled color={"warning"} />;
      title = translate("call.NOANSWER");
      break;
    case "CANCEL":
      statusIcon = <Cancel color={"error"} />;
      title = translate("call.CANCEL");
      break;
    default:
      statusIcon = <div />;
      break;
  }

  return !!result ? (
    <Tooltip sx={{ display: "block", lineHeight: "2" }} title={title}>
      {statusIcon}
    </Tooltip>
  ) : null;
}
