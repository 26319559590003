import React, { createContext, ReactNode, useEffect, useState, useMemo } from "react";
import {
  AutoReplyBotSettings,
  BulkMessagingTier,
  CrmType,
  FirestoreContextProps,
  UserSettings
} from "../@types/firestore";
import useAuth from "../hooks/useAuth";
import firebase from "firebase/compat/app";
import moment from "moment-timezone";
import { parsePhoneNumber } from "utils/Haim/phoneNumbersUtils";
import { useLocation } from "react-router";
import { MAX_MESSAGES_IN_FREE_TRIAL } from "logic/Consts";
import { db } from "firebaseConfig";
import { getUpdatedTimeFromFirestoreDoc, removeUndefined } from "utils/Haim/firestoreUtils";
import { randomNumber } from "utils/Haim/utils";
import { checkListingAliasAvailability } from "utils/listings/helper";
import { organizationConverter } from "../@types/listings/organization";
import type { User } from "../@types/listings/user";
import type { Organization } from "../@types/listings/organization";
import type { ListingData } from "../@types/listings/new";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import { GenericConverter } from "utils/firestoreConverters";

const FirestoreContext = createContext({} as FirestoreContextProps);

type FirestoreProviderProps = {
  children: ReactNode;
};

function FirestoreProvider({ children }: FirestoreProviderProps) {
  const { user, isAuthenticated } = useAuth();

  const [myVirtualNumbers, setMyVirtualNumbers] = useState<Array<string>>([]);
  const [hasAccessToOtherUsers, setHasAccessToOtherUsers] = useState<Array<string>>([]);
  const [myApiToken, setMyApiToken] = useState<any>(null);
  const [myBulkMessagingTier, setMyBulkMessagingTier] = useState<any>(null);

  const [callersByVirtualNumber, setCallersByVirtualNumber] = useState<Map<string, any>>(new Map());
  const [callsByVirtualNumber, setCallsByVirtualNumber] = useState<Map<string, any>>(new Map());
  const [shouldFetchAllCallsByVirtualNumber, setShouldFetchAllCallsByVirtualNumber] =
    useState<boolean>(false);
  const [featureFlags, setFeatureFlags] = useState<any>({});
  const [userSettings, setUserSettings] = useState<UserSettings & { email: string }>(null);
  const [autoReplyBotSettings, setAutoReplyBotSettings] = useState<AutoReplyBotSettings>(null);
  const { search } = useLocation();
  const [messagesSentCount, setMessagesSentCount] = useState(0);
  const [listingData, setListingData] = useState<null | ListingData>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingListingData, setIsLoadingListingData] = useState(false);
  const [organization, setOrganization] = useState<null | undefined | Organization>(undefined);
  const isOrgAdmin =
    user && isAuthenticated && organization && (organization.admins ?? []).includes(user.email);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    console.log("Fetching dashboard settings for user", user.email);

    setIsLoadingUser(true);
    return db
      .collection("dashboard-users-v2")
      .doc(user.email)
      .withConverter(GenericConverter<UserSettings>())
      .onSnapshot(
        (snapshot) => {
          if (!snapshot.exists) {
            console.log("Got no results for user:", user.email);
            return;
          }

          const data = snapshot.data() as UserSettings;

          setUserSettings({ ...data, email: user.email });

          const {
            virtualNumbers,
            apiToken,
            bulkMessagingTier,
            hasAccessToOtherUsers,
            featureFlags
          } = snapshot.data() as any;

          console.log("My dashboard data:", { virtualNumbers, apiToken, bulkMessagingTier });
          setHasAccessToOtherUsers(hasAccessToOtherUsers || []);
          setMyVirtualNumbers(virtualNumbers || []);
          setMyApiToken(apiToken || "");
          setMyBulkMessagingTier(bulkMessagingTier);
          setFeatureFlags(featureFlags || {});
          setIsLoadingUser(false);
        },
        () => {
          setIsLoadingUser(false);
        }
      );
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!user || !userSettings) return;

    if (userSettings.crmType !== CrmType.realEstate) return;

    setIsLoadingListingData(true);

    const backfillListingAliases = async (): Promise<void> => {
      const normalizedAlias = user.displayName.split(" ").join("").toLowerCase();

      let available = false;

      let newListingAlias = normalizedAlias;

      const firstGeneratedAliasAvailable = await checkListingAliasAvailability(newListingAlias);

      if (firstGeneratedAliasAvailable) available = true;

      while (!available) {
        newListingAlias = normalizedAlias + randomNumber(1000, 9999);
        const newGeneratedAliasAvailable = await checkListingAliasAvailability(newListingAlias);

        if (newGeneratedAliasAvailable) available = true;
      }

      const { ownerFullName, email, photoURL, ownerPhoneNumber } = userSettings;

      const newUserSettings: User = {
        name: ownerFullName,
        email: email,
        avatarUrl: photoURL,
        listingBio: "",
        phoneNumber: ownerPhoneNumber,
        listingAlias: newListingAlias,
        coverAvatarUrl: null,
        coverAvatarColor: null
      };

      void updateUserListingData(newUserSettings);
    };

    const listingDataSnapshot = db
      .collection("dashboard-users-v2")
      .doc(user.email)
      .collection("listingData")
      .doc(user.email)
      .onSnapshot(
        (snapshot) => {
          if (!snapshot.exists) {
            void backfillListingAliases();
            return;
          }

          const newListingData = snapshot.data() as ListingData;

          setListingData(newListingData);

          setIsLoadingListingData(false);
        },
        () => setIsLoadingListingData(false)
      );

    return listingDataSnapshot;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userSettings]);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    console.log("Fetching auto reply bot settings for user", user.email);

    return db
      .collection("official-whatsapp-bots")
      .where("ownerMail", "==", user.email)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          console.log("Got no results for user:", user.email);
          return;
        }

        const data = snapshot.docs[0].data() as AutoReplyBotSettings;

        setAutoReplyBotSettings(data);
      });
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!user || !isAuthenticated) return;

    const getOrganization = async (): Promise<void> => {
      try {
        const organizationResult = await db
          .collection("dashboard-orgs")
          .withConverter(organizationConverter)
          .where("users", "array-contains", user.email)
          .get();

        if (organizationResult.empty) {
          return;
        }

        setOrganization(organizationResult.docs[0]?.data());
      } catch (error) {
        console.error(error);
      }
    };

    getOrganization();
  }, [user, isAuthenticated]);

  // backfilling user settings, adding photo url from google if missing
  useEffect(() => {
    if (!user || !userSettings) {
      return;
    }

    let newUserSettings: Partial<UserSettings> = {};

    if (!userSettings.photoURL && user.photoURL) {
      newUserSettings.photoURL = user.photoURL;
    }

    if (!userSettings.ownerFullName && user.displayName) {
      newUserSettings.ownerFullName = user.displayName;
    }

    if (!userSettings.crmType) {
      console.warn("No CRM type defined. Going with real estate");
      newUserSettings.crmType = CrmType.realEstate;
    }

    if (Object.keys(newUserSettings).length) {
      updateUserSettings(newUserSettings);
    }
  }, [user, userSettings]);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (isAuthenticated && user) {
        console.log("User auth changed, checking if it is a new user", user.email);

        const userResult = await db.collection("dashboard-users-v2").doc(user.email).get();

        if (!userResult.exists) {
          console.log("Got no results for user, he is probably a new user:", user.email);
          const wantedCrmType = new URLSearchParams(search).get("crmType") ?? CrmType.realEstate;

          let result = await db
            .collection("dashboard-users-v2")
            .doc(user.email)
            .set({
              apiToken: Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2),
              virtualNumbers: [],
              bulkMessagingTier: BulkMessagingTier.free,
              crmType: wantedCrmType
            });

          try {
            ReactPixel?.track("Lead", { email: user.email });
          } catch (error) {
            console.error("Error in ReactPixel", error);
          }
          console.log("Created new data for user:", user.email, result);
        }
      }
    };
    checkAuthStatus();
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    console.log("Fetching dashboard settings for user: ", user.email);

    const snapshotCancellations = [];

    const snapshotCancellation2 = db
      .collection("dashboard-users-v2")
      .doc(user.email)
      .collection("sentMessages")
      .limit(MAX_MESSAGES_IN_FREE_TRIAL + 10)
      .onSnapshot((docs) => {
        setMessagesSentCount(docs?.size ?? 0);
      });

    snapshotCancellations.push(snapshotCancellation2);

    return () => {
      console.log("Running snapshot cancellations...");
      snapshotCancellations.forEach((func) => func());
    };
  }, [user, isAuthenticated, hasAccessToOtherUsers]);

  useEffect(() => {
    if (!user || !isAuthenticated || !myVirtualNumbers || myVirtualNumbers.length === 0) {
      return;
    }

    const snapshotCancellations = [];

    for (const virtualNumber of myVirtualNumbers) {
      console.log("Fetching callers for virtual number: ", virtualNumber);

      const snapshotCancellation = db
        .collection("payCall-settings")
        .doc(virtualNumber)
        .collection("callers")
        .onSnapshot((snapshot) => {
          console.log("Firebase callers snapshot for virtual number", virtualNumber);

          const entries: Array<any> = snapshot.docs
            .filter((doc) => doc.id != null)
            .map((doc) => {
              const data = doc.data();
              data.id = doc.id;
              data.updatedTime = getUpdatedTimeFromFirestoreDoc(doc);
              data._metadata = data.metadata ?? {};
              data._metadata.callerPhoneNumber = parsePhoneNumber(data.id);
              data._metadata.firestorePath = doc.ref.path;
              data.contactOrigin = "Webot virtual number";
              data.contactOriginAdditionalInfo = virtualNumber;
              return data;
            });
          setCallersByVirtualNumber((oldValue) => {
            var newValue = new Map(oldValue);
            newValue.set(virtualNumber, entries);
            return newValue;
          });
        });
      snapshotCancellations.push(snapshotCancellation);
    }

    return () => {
      console.log("Running snapshot cancellations...");
      snapshotCancellations.forEach((func) => func());
    };
  }, [user, isAuthenticated, myVirtualNumbers]);

  useEffect(() => {
    if (
      !db ||
      !user ||
      !isAuthenticated ||
      !myVirtualNumbers ||
      myVirtualNumbers.length === 0 ||
      !shouldFetchAllCallsByVirtualNumber
    ) {
      return;
    }

    const snapshotCancellations = [];

    for (const virtualNumber of myVirtualNumbers) {
      console.log("Fetching calls for virtual number: ", virtualNumber);

      const snapshotCancellation = db
        .collection("payCall-settings")
        .doc(virtualNumber)
        .collection("calls-summary")
        .onSnapshot((snapshot) => {
          console.log("Firebase calls summary snapshot for virtual number", virtualNumber);

          const entries: Array<any> = snapshot.docs
            .filter((doc) => doc.id != null)
            .map((doc) => {
              const data = doc.data();
              data._metadata = data.metadata ?? {};
              data._metadata.callerPhoneNumber = parsePhoneNumber(data.callerNumber);
              data._metadata.firestorePath = doc.ref.path;
              return data;
            });
          setCallsByVirtualNumber((oldValue) => {
            var newValue = new Map(oldValue);
            newValue.set(virtualNumber, entries);
            return newValue;
          });
        });
      snapshotCancellations.push(snapshotCancellation);
    }

    return () => {
      console.log("Running snapshot cancellations...");
      snapshotCancellations.forEach((func) => func());
    };
  }, [user, isAuthenticated, myVirtualNumbers, shouldFetchAllCallsByVirtualNumber]);

  useEffect(() => {
    console.debug("debug running effect");
    return () => {
      console.debug("debug effect cleanup...");
    };
  }, [myVirtualNumbers, shouldFetchAllCallsByVirtualNumber]);

  const updateUserSettings = async (data: Partial<UserSettings>) => {
    console.log(`Updating user settings for ${user.email} with:`, data);
    await db.collection("dashboard-users-v2").doc(user.email).set(data, { merge: true });
  };

  const updateUserListingData = async (data: Partial<User>): Promise<void> => {
    await db
      .collection("dashboard-users-v2")
      .doc(user.email)
      .collection("listingData")
      .doc(user.email)
      .set(data, { merge: true });
  };

  const saveSentMessage = async (messageObject: any) => {
    removeUndefined(messageObject);

    console.log("Saving message for ", user, messageObject);

    await db
      .collection("dashboard-users-v2")
      .doc(user.email)
      .collection("sentMessages")
      .doc()
      .set(messageObject);
  };

  const memoizedTeamMembers = useMemo(
    () => hasAccessToOtherUsers.map((u) => u.trim()),
    [hasAccessToOtherUsers]
  );

  return (
    <FirestoreContext.Provider
      value={{
        userSettings,
        autoReplyBotSettings,
        myVirtualNumbers,
        callsByVirtualNumber,
        setShouldFetchAllCallsByVirtualNumber,
        myApiToken,
        myBulkMessagingTier,
        featureFlags,
        updateUserSettings,
        updateUserListingData,
        saveSentMessage,
        messagesSentCount,
        listingData,
        hasAccessToOtherUsers: memoizedTeamMembers,
        isLoadingUser,
        isLoadingListingData,
        organization,
        isOrgAdmin
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
}

export { FirestoreProvider, FirestoreContext };
