import { Icon, IconifyIcon } from "@iconify/react";
import { paramCase } from "change-case";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from "@material-ui/core";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import GenericMenu, { GenericMenuRowProps } from "./GenericMenu";

// ----------------------------------------------------------------------

type MoreMenuProps = {
  menuRows: GenericMenuRowProps[];
  actionContext: any;
};

export default function MoreMenu({ menuRows, actionContext = null }: MoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} sx={{ padding: 0, margin: 0 }}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <GenericMenu
        menuRows={menuRows}
        anchorEl={ref.current}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        actionContext={actionContext}
        openHorizontalPosition="right"
      />
    </>
  );
}
