import { isValidPhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js/max";

const DEFAULT_COUNTRY_CODE = "IL";

export function parsePhoneNumber(phoneNumber: string) {
  if (typeof phoneNumber !== "string") {
    phoneNumber = (phoneNumber as any)?.toString();
  }
  if (!phoneNumber) {
    return null;
  }

  var phoneNumberObj = parsePhoneNumberFromString(phoneNumber, DEFAULT_COUNTRY_CODE);

  if (!phoneNumberObj) {
    console.warn(
      "Could not parse phone number: ",
      phoneNumber,
      ", Will try adding a + at the start (hack because we currently save intl number without + in database)"
    );
    phoneNumber = `+${phoneNumber}`;
    phoneNumberObj = parsePhoneNumberFromString(phoneNumber, DEFAULT_COUNTRY_CODE);
    if (!phoneNumberObj) {
      console.warn("Definitively Could not parse phone number: ", phoneNumber);
      return null;
    }
  }

  if (
    phoneNumberObj.country !== "BR" &&
    (!phoneNumberObj.isValid() || !isValidPhoneNumber(phoneNumberObj.formatInternational()))
  ) {
    console.debug("Number is not valid", phoneNumberObj);
    return null;
  }

  return phoneNumberObj;
}

// Will return something like +972527485568
export function toInternationalFormat(phoneNumber: string) {
  const phoneNumberObj = parsePhoneNumber(phoneNumber);
  if (!phoneNumberObj) {
    console.log("Could not convert to international format:", phoneNumber);
    return null;
  }

  return phoneNumberObj.format("E.164");
}

// TODO - this is pending a backend change, where we will save all phone numbers as international numbers
export function getLocalPhoneNumberIfIsraeliNumber(phoneNumberStr) {
  var phoneNumberObj = parsePhoneNumber(phoneNumberStr);

  if (!phoneNumberObj || !phoneNumberObj.country) {
    return null;
  }

  if (phoneNumberObj.country != "IL") {
    return phoneNumberObj.format("E.164");
  } else {
    return phoneNumberObj.formatNational().replace(/\D/g, "");
  }
}

export function isMobile(phoneNumber: string) {
  console.log("Checking if phone number is mobile: ", phoneNumber);
  const phoneNumberInternational = toInternationalFormat(phoneNumber);

  if (!phoneNumberInternational) {
    console.log("Bad phone number");
    return false;
  }

  // Only Israel support FOR NOW (9725)
  return phoneNumberInternational.startsWith(`9725`);
}
