import {
  customerSeriousnessPossibleValues,
  customerTypePossibleValues,
  customerBudgetCol,
  customerSeriousnessCol,
  customerTypeCol,
  roomsMaxCol,
  roomsMinCol
} from "logic/CrmSpecifications/RealEstateCrmSpecifications";

const parseAndValidateBudget = (row: any): { parsedBudget: string; error?: Error } => {
  try {
    var budget = undefined;
    var otherFormats = [customerBudgetCol.headerName, "מחיר", "budget"];

    for (const budgetColStr of otherFormats) {
      budget = row[budgetColStr];
      if (budget !== undefined && budget !== "") {
        break;
      }
    }

    budget = budget?.toString().replace(/\D/g, "") ?? "";
    if ((budget && isNaN(budget)) || (!budget && row[customerBudgetCol.headerName])) {
      throw new Error(`תקציב לא תקין: '${row[customerBudgetCol.headerName]}'`);
    }

    return { parsedBudget: budget };
  } catch (error) {
    return { parsedBudget: row[customerBudgetCol.headerName], error };
  }
};

const validateRoomSizes = (row: any): Error => {
  try {
    const roomsMin = row[roomsMinCol.headerName];
    if (roomsMin && isNaN(roomsMin)) {
      throw new Error(`מספר חדרים לא תקין: '${roomsMin}'`);
    }

    const roomsMax = row[roomsMaxCol.headerName];
    if (roomsMax && isNaN(roomsMax)) {
      throw new Error(`מספר חדרים לא תקין: '${roomsMax}'`);
    }
  } catch (error) {
    return error;
  }
};

const validateCustomerType = (row: any): Error => {
  try {
    const customerType = row[customerTypeCol.headerName];
    if (customerType && !customerTypePossibleValues.includes(customerType)) {
      throw new Error(`סוג לקוח לא תקין: '${customerType}'`);
    }
  } catch (error) {
    return error;
  }
};

const validateCustomerSeriousness = (row: any): Error => {
  try {
    const customerSeriousness = row[customerSeriousnessCol.headerName];
    if (customerSeriousness && !customerSeriousnessPossibleValues.includes(customerSeriousness)) {
      throw new Error(`ערך רצינות לקוח לא תקין: '${customerSeriousness}'`);
    }
  } catch (error) {
    return error;
  }
};

export const validateRealEstateColumnValues = (parsedRow: any, rawRow: any) => {
  const { parsedBudget, error: budgetError } = parseAndValidateBudget(rawRow);
  parsedRow.customerBudget = parsedBudget;

  const roomSizesError = validateRoomSizes(rawRow);
  const customerTypeError = validateCustomerType(rawRow);
  const customerSeriousnessError = validateCustomerSeriousness(rawRow);

  parsedRow.errors.push(budgetError, roomSizesError, customerTypeError, customerSeriousnessError);
  parsedRow.errors = parsedRow.errors.filter((e) => !!e);
};
