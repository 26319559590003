import { Icon, IconifyIcon } from "@iconify/react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";

// ----------------------------------------------------------------------

export type GenericMenuRowProps = {
  name: string;
  icon: string | IconifyIcon;
  color?: string;
  onClick: (actionContext: any) => void;
};
type GenericMenuProps = {
  menuRows: GenericMenuRowProps[];
  anchorEl: Element | ((element: Element) => Element);
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  actionContext: any;
  openHorizontalPosition: "left" | "right" | "center";
};

export default function GenericMenu({
  menuRows,
  anchorEl,
  isOpen,
  setIsOpen,
  actionContext = null,
  openHorizontalPosition = "right"
}: GenericMenuProps) {
  return (
    <>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: "auto", maxWidth: "100%" }
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: openHorizontalPosition }}
        transformOrigin={{ vertical: "top", horizontal: openHorizontalPosition }}
      >
        {menuRows.map((row) => (
          <MenuItem
            key={row.name}
            onClick={() => {
              setIsOpen(false);
              row.onClick(actionContext);
            }}
            sx={{ color: row.color ?? "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={row.icon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={row.name} primaryTypographyProps={{ variant: "body2" }} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
