import { createContext, ReactNode, useEffect, useState } from "react";
import { CrmSpecificationsContextProps, ICrmSpecifications } from "../@types/crmSpecifications";
import useAuth from "../hooks/useAuth";
import useFirestore from "hooks/useFirestore";

// import axios from '../../utils/axios';
import axios, { AxiosResponse } from "axios";
import { CrmType } from "../@types/firestore";
import { RealEstateCrmSpecifications } from "../logic/CrmSpecifications/RealEstateCrmSpecifications";
import { GenericCrmSpecifications } from "../logic/CrmSpecifications/GenericCrmSpecifications";
import { AgGridColumnProps } from "ag-grid-react";

const CrmSpecificationsContext = createContext({} as CrmSpecificationsContextProps);

type CrmSpecificationsProviderProps = {
  children: ReactNode;
};

const getCrmSpecifications = (crmType: CrmType): ICrmSpecifications => {
  switch (crmType) {
    case CrmType.generic:
      return new GenericCrmSpecifications();
    case CrmType.realEstate:
      return new RealEstateCrmSpecifications();
    default:
      return null;
  }
};

function CrmSpecificationsProvider({ children }: CrmSpecificationsProviderProps) {
  const { userSettings } = useFirestore();

  const [currentCrmSpecifications, setCurrentCrmSpecifications] =
    useState<ICrmSpecifications>(null);
  const [currentDataColumns, setCurrentDataColumns] = useState<AgGridColumnProps[]>([]);

  useEffect(() => {
    setCurrentCrmSpecifications(getCrmSpecifications(userSettings?.crmType));
  }, [userSettings]);

  useEffect(() => {
    setCurrentDataColumns(currentCrmSpecifications?.getDefaultDataColumns() ?? []); // todo merge with existing data too
  }, [currentCrmSpecifications]);

  const currentEditableDataColumns = currentDataColumns?.filter((c) => c.editable);
  const currentDataColumnsFieldToHeaderNameMap = new Map(
    currentDataColumns?.map((obj) => [obj.field, obj.headerName])
  );
  const currentDataColumnsHeaderNameToFieldMap = new Map(
    currentDataColumns?.map((obj) => [obj.headerName, obj.field])
  );

  return (
    <CrmSpecificationsContext.Provider
      value={{
        currentCrmSpecifications,
        currentDataColumns,
        currentEditableDataColumns,
        currentDataColumnsFieldToHeaderNameMap,
        currentDataColumnsHeaderNameToFieldMap
      }}
    >
      {children}
    </CrmSpecificationsContext.Provider>
  );
}

export { CrmSpecificationsProvider, CrmSpecificationsContext };
