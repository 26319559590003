import moment from "moment";
import firebase from "firebase/compat/app";
import { parsePhoneNumber } from "./phoneNumbersUtils";

export function parseFirestoreDocument(obj: firebase.firestore.DocumentData) {
  // Converting firestore timestamp to Date
  const newObj = Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [
      k,
      v instanceof firebase.firestore.Timestamp ? v.toDate() : v
    ])
  );

  return newObj;
}

export function getUpdatedTimeFromFirestoreDoc(doc: any) {
  const seconds = doc?._delegate?._document?.version?.timestamp?.seconds;
  const dateObj = new Date(seconds * 1000);
  if (moment(dateObj)?.isBefore(moment().subtract(40, "years"))) {
    return null;
  } else {
    return dateObj;
  }
}

export function removeUndefined(data) {
  Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]); //filtering out 'undefined'
}

export function generateMetadataFromContact(email: string, contactId: string) {
  const _metadata = {
    callerPhoneNumber: parsePhoneNumber(contactId),
    firestorePath: `dashboard-users-v2/${email}/contacts/${contactId}`
  };

  return _metadata;
}
