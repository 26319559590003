import useAuth from "hooks/useAuth";
import useFirestore from "hooks/useFirestore";
import useSettings from "hooks/useSettings";
import { useEffect, useState } from "react";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";

const WEBOT_PIXEL_ID = "347029499885658";

export const MetaPixel = () => {
  const { user } = useAuth();
  const { userSettings } = useFirestore();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!user || !userSettings || initialized) {
      return;
    }
    try {
      console.log("Setting up pixel...");
      const advancedMatching = {
        em: user.email,
        fn: userSettings.ownerFullName,
        ph: userSettings.ownerPhoneNumber
      } as AdvancedMatching;
      ReactPixel.init(WEBOT_PIXEL_ID, advancedMatching, { autoConfig: true, debug: false });
      ReactPixel.grantConsent();
      ReactPixel.pageView();
      setInitialized(true);
    } catch (error) {
      console.log("error setting up pixel", error);
    }
  }, [user, userSettings]);

  return null;
};
