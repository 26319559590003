import { Theme } from "@material-ui/core/styles";

// ----------------------------------------------------------------------

export default function Grid(theme: Theme) {
  return {
    MuiGrid: {
      styleOverrides: {}
    }
  };
}
