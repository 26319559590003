import { capitalize } from "lodash";

// ----------------------------------------------------------------------
function getFirstCharacter(name: string) {
  return capitalize(name && name.charAt(0));
}

function getAvatarColor(name: string) {
  const charWithModulo = (name ?? "").charCodeAt(0) % 5;
  if (charWithModulo === 0) return "primary";
  if (charWithModulo === 1) return "info";
  if (charWithModulo === 2) return "success";
  if (charWithModulo === 3) return "warning";
  if (charWithModulo === 4) return "error";
  return "default";
}

export default function createAvatar(name: string) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name)
  } as const;
}
