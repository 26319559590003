/* eslint-disable import/no-duplicates */
import { createContext, ReactNode, useEffect, useReducer, useState } from "react";
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from "../@types/authentication";
import { firebaseApp } from "firebaseConfig";

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ["haimbendanan@gmail.com"];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  Initial = "INITIALISE"
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<AuthUser | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          // Haim - comenting this out, maybe will use in the future
          // const docRef = firebase.firestore().collection('users').doc(user.uid);
          // docRef
          //   .get()
          //   .then((doc) => {
          //     if (doc.exists) {
          //       setProfile(doc.data());
          //     }
          //   })
          //   .catch((error) => {
          //     console.error(error);
          //   });

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const auth = state.user ?? {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(auth.email) ? "admin" : "user",
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || "",
          country: profile?.country || "",
          address: profile?.address || "",
          state: profile?.state || "",
          city: profile?.city || "",
          zipCode: profile?.zipCode || "",
          about: profile?.about || "",
          isPublic: profile?.isPublic || false,
          getIdToken: () => firebaseApp.auth().currentUser.getIdToken()
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
