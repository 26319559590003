import { Stack, Typography, Radio, useTheme, Paper, RadioGroup, FormControlLabel, FormControlUnstyled } from '@material-ui/core';
import { CrmType } from './../../../@types/firestore';

import { doc, updateDoc } from 'firebase/firestore';
import useAuth from 'hooks/useAuth';
import { useState, type ChangeEvent, type Dispatch, type SetStateAction, useEffect } from 'react';
import { usersCollection } from 'utils/firestoreConverters';
import useFirestore from 'hooks/useFirestore';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


type UserRoleInputProps = {
  onboarding?: boolean;
  onLoadingChange: Dispatch<SetStateAction<boolean>>;
  fireSnackbarOpen?: (message?: string) => void;
  setCanMoveToNextStep?: Dispatch<SetStateAction<boolean>>;
};

export function UserRoleInput({ onboarding, onLoadingChange, fireSnackbarOpen, setCanMoveToNextStep }: UserRoleInputProps) {
  const {user} = useAuth();
  const { userSettings } = useFirestore();
  const theme = useTheme();
  const {t} = useTranslation();
  const [pickedRole, setPickedRole] = useState<CrmType | null>(userSettings?.crmType ?? null);

  useEffect(() => {
    if(pickedRole !== userSettings?.crmType) {
      setPickedRole(userSettings?.crmType);
    }
  }
  , [userSettings?.crmType]);
  
  useEffect(() => {
    if (pickedRole) {
      setCanMoveToNextStep?.(true);
    } else {
      setCanMoveToNextStep?.(false);
    }
  }, [pickedRole]);

  const handleRadioChange = async ({ target: { value, checked } }: ChangeEvent<HTMLInputElement>) => {
    console.log('change:', value, checked);
    const updatedRole = value as CrmType;

    updateRole(updatedRole);
  };

  const updateRole = async (crmType: CrmType) => {
    setPickedRole(crmType);
    onLoadingChange?.(true);
    await updateDoc(doc(usersCollection, user?.email), {
      crmType
    });
    onLoadingChange?.(false);
    fireSnackbarOpen?.('Profile updated');
  };

  const userRolesInputChoices: {
    value: CrmType;
    label: string;
    sublabel: string;
  }[] = [
    {
      value: CrmType.realEstate,
      label: t("onboarding.userRoleInput.realEstateLabel"),
      sublabel: t("onboarding.userRoleInput.realEstateSublabel")
    },
    {
      value: CrmType.generic,
      label: t("onboarding.userRoleInput.genericLabel"),
      sublabel: t("onboarding.userRoleInput.genericSublabel")
    }
  ];

  return (
    <Stack gap={1}>
      {onboarding && (
        <Typography variant='body1' fontWeight='bold' sx={{ pt: 4 }}>
          {t("onboarding.userRoleInput.title")}
        </Typography>
      )}

      <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    aria-required={true}
    name="radio-buttons-group"
    value={pickedRole}
    onChange={handleRadioChange}
  >      <Stack direction='column' spacing={2.5} sx={{ pt: 2 }}>

        {userRolesInputChoices.map((choice) => (
          <Paper
            key={choice.value}
            variant='outlined'
            onClick={() => updateRole(choice.value)}
            style={{ cursor: 'pointer' }}
            sx={{
              p: 2,
              borderColor: pickedRole === choice.value ? theme.palette.grey[800] : theme.palette.grey[300],
              backgroundColor: pickedRole === choice.value ? theme.palette.grey[50] : theme.palette.common.white
            }}
          >
            <Stack direction='row' alignItems={'center'}>
              <Radio  value={choice.value} />
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1'>{choice.label}</Typography>
                <Typography variant='body2' color={theme.palette.grey[500]}>
                  {choice.sublabel}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        ))}
               </Stack>

        </RadioGroup>
    </Stack>
  );
}
