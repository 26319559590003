import { Grid } from "@material-ui/core";

import "moment/locale/he"; // without this line, language is not imported
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "moment/locale/he"; // without this line, language is not imported
import { useTheme } from "@material-ui/core/styles";

import { getIcon } from "utils/getFileFormat";
import VideoTutorialButton from "../Common/VideoTutorialButton";
import { getYoutubeLink } from "utils/Haim/utils";

export default function UploadExplanationGeneric() {
  const { palette } = useTheme();

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md="auto">
          <VideoTutorialButton
            fontColor={palette.text.primary}
            backgroundColor="#cffccc"
            tutorialName="סרטון הדרכה - אקסל"
            url={getYoutubeLink("maHP2coUNv4")}
            endIcon={getIcon("file_type_excel")}
          />
        </Grid>
      </Grid>
    </>
  );
}
