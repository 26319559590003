import {
  Stack,
  Typography,
  Radio,
  useTheme,
  Paper,
  RadioGroup,
  FormControlLabel,
  FormControlUnstyled,
  Checkbox,
  Box,
  List
} from "@material-ui/core";
import { CrmType } from "../../../@types/firestore";

import { doc, updateDoc } from "firebase/firestore";
import useAuth from "hooks/useAuth";
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { usersCollection } from "utils/firestoreConverters";
import useFirestore from "hooks/useFirestore";
import { FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Image } from "components/Image";
import {
  ForwardToInbox,
  PhoneMissed,
  HomeWork,
  PersonSearch,
  ManageAccounts,
  PersonAdd
} from "@material-ui/icons";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";

type WebotFeaturesPickerProps = {
  onboarding?: boolean;
  onLoadingChange: Dispatch<SetStateAction<boolean>>;
  fireSnackbarOpen?: (message?: string) => void;
  setCanMoveToNextStep?: Dispatch<SetStateAction<boolean>>;
};

export function WebotFeaturesPicker({
  onboarding,
  onLoadingChange,
  fireSnackbarOpen,
  setCanMoveToNextStep
}: WebotFeaturesPickerProps) {
  const { user } = useAuth();
  const { userSettings } = useFirestore();
  const theme = useTheme();
  const { t } = useTranslation();
  const [interestedWebotFeatures, setInterestedWebotFeatures] = useState<string[] | null>(
    userSettings?.interestedWebotFeatures ?? null
  );

  // useEffect(() => {
  //   if (interestedWebotFeatures !== userSettings?.interestedWebotFeatures) {
  //     setInterestedWebotFeatures(userSettings?.interestedWebotFeatures);
  //   }
  // }, [userSettings?.interestedWebotFeatures]);

  useEffect(() => {
    setCanMoveToNextStep?.(true);
  }, []);

  const updateWebotFeatures = async (updates: string[]) => {
    setInterestedWebotFeatures(updates);
    onLoadingChange?.(true);
    await updateDoc(doc(usersCollection, user?.email), {
      interestedWebotFeatures: updates
    });
    onLoadingChange?.(false);
    fireSnackbarOpen?.("Profile updated");
  };

  const userRolesInputChoices: {
    value: string;
    label: string;
    sublabel: string;
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    IconColor?: string;
  }[] = [
    {
      value: t("onboarding.webotFeaturesPicker.bulkMessageCustomers"),
      label: t("onboarding.webotFeaturesPicker.bulkMessageCustomers"),
      sublabel: t("onboarding.webotFeaturesPicker.bulkMessageCustomersSublabel"),
      Icon: ForwardToInbox,
      IconColor: "#014dc9"
    },
    {
      value: t("onboarding.webotFeaturesPicker.helpAnsweringPhones"),
      label: t("onboarding.webotFeaturesPicker.helpAnsweringPhones"),
      sublabel: t("onboarding.webotFeaturesPicker.helpAnsweringPhonesSublabel"),
      Icon: PhoneMissed,
      IconColor: "#FF0000"
    },
    {
      value: t("onboarding.webotFeaturesPicker.listingsManagement"),
      label: t("onboarding.webotFeaturesPicker.listingsManagement"),
      sublabel: t("onboarding.webotFeaturesPicker.listingsManagementSublabel"),
      Icon: HomeWork,
      IconColor: "#FFA500"
    },
    {
      value: t("onboarding.webotFeaturesPicker.gettingMoreLeads"),
      label: t("onboarding.webotFeaturesPicker.gettingMoreLeads"),
      sublabel: t("onboarding.webotFeaturesPicker.gettingMoreLeadsSublabel"),
      Icon: PersonSearch,
      IconColor: "#228B22"
    },
    {
      value: t("onboarding.webotFeaturesPicker.organizeMyLeads"),
      label: t("onboarding.webotFeaturesPicker.organizeMyLeads"),
      sublabel: t("onboarding.webotFeaturesPicker.organizeMyLeadsSublabel"),
      Icon: ManageAccounts,
      IconColor: "#FFD700"
    },
    {
      value: t("onboarding.webotFeaturesPicker.convertLeadsToCustomers"),
      label: t("onboarding.webotFeaturesPicker.convertLeadsToCustomers"),
      sublabel: t("onboarding.webotFeaturesPicker.convertLeadsToCustomersSublabel"),
      Icon: PersonAdd,
      IconColor: "#FF69B4"
    }
  ];

  const handleFeatureChange = (name: string, value: boolean) => {
    if (!value) {
      updateWebotFeatures(interestedWebotFeatures?.filter((feature) => feature !== name));
    } else if (value && !interestedWebotFeatures?.includes(name)) {
      console.log("adding feature", name);
      updateWebotFeatures([...(interestedWebotFeatures ?? []), name]);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFeatureChange(event.target.name, event.target.checked);
  };

  return (
    <Stack gap={1}>
      {onboarding && (
        <Typography variant="body1" fontWeight="bold" sx={{ pt: 4 }}>
          {t("onboarding.webotFeaturesPicker.title")}
        </Typography>
      )}

      <Stack direction="column" spacing={2.5} sx={{ pt: 2 }}>
        {userRolesInputChoices.map((choice) => (
          <Paper
            key={choice.value}
            variant="outlined"
            onClick={() =>
              handleFeatureChange(choice.value, !interestedWebotFeatures?.includes(choice.value))
            }
            style={{ cursor: "pointer" }}
            sx={{
              p: 2,
              borderColor: interestedWebotFeatures?.includes(choice.value)
                ? theme.palette.grey[800]
                : theme.palette.grey[300],
              backgroundColor: interestedWebotFeatures?.includes(choice.value)
                ? theme.palette.grey[50]
                : theme.palette.common.white
            }}
          >
            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
              <Stack direction="row" alignItems={"center"}>
                <Checkbox
                  checked={interestedWebotFeatures?.includes(choice.value) ?? false}
                  onChange={handleCheckboxChange}
                />
                <Stack direction="column" spacing={0}>
                  <Typography variant="subtitle1">{choice.label}</Typography>
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    {choice.sublabel}
                  </Typography>
                </Stack>
              </Stack>
              {choice.Icon && (
                <Box sx={{ p: 1 }}>
                  <choice.Icon
                    fontSize={"large"}
                    sx={{ width: "3em", height: "3em", color: choice.IconColor }}
                  />
                </Box>
              )}
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
}
